import Image from 'next/image';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import AffiliateLinkContainer from '../affiliate-link/affiliate-link-container';

const partners = [
  {
    affName: 'myLabBox/Top',
    alt: 'MyLabBox',
    image: 'https://cdn.stdwatch.com/assets/optimized/MLB/mlb_new_logo.png?',
  },
  {
    affName: 'STDCheck/Home',
    alt: 'STDCheck',
    image: 'https://media.graphassets.com/6r1Arw1zRBmpKpbClEAP',
  },
  {
    affName: 'HealthLabs/STDtesting',
    alt: 'HealthLabs',
    image: 'https://media.graphassets.com/N4fnaMn3T1erLG6nCzdw',
  },
];


export function ChartTop3(props) {
  return (
    <div>
      <div className=" bg-indigo-100 p-2 rounded-lg text-center font-light text-cyan-900">Top Health Testing Services</div>
      <ul className="grid grid-cols-1 divide-y divide-gray-200  border-b border-gray-200 mb-10 ">
        {partners.map((partner, index) => (
          <AffiliateLinkContainer                 //  legacyBehavior
            key={index}
            affName={partner.affName}
            section="sidebar-right"
            component="top-3"
          >
            <div key={partner.alt} className="col-span-1 flex item-center justify-between p-2 ">
              <p >{index + 1}</p>
              <li key={index} className=" w-3/5 relative ">
                <Image
                  src={partner.image}
                  alt={partner.alt}
                  layout="fill"
                />
              </li>

              <span className="relative flex h-9 w-9 items-center justify-center rounded-full bg-indigo-100 hover:bg-indigo-200 ">
                <span className="sr-only">Open options</span>
                <ChevronRightIcon className="h-3 w-3 text-cyan-900" aria-hidden="true" />
                <div className={"animate-[ping_4s_cubic-bezier(0,0,0.2,1)_infinite] absolute inline-flex h-7 w-7 rounded-full bg-indigo-300 opacity-90"}>
                  <button key={partner.affName} className="relative inline-flex h-9 w-9 items-center justify-center"></button>
                </div>
              </span>

            </div>
          </AffiliateLinkContainer>
        ))}
      </ul>
    </div>
  );
}
export default ChartTop3;
