import { RichText } from '@graphcms/rich-text-react-renderer';
import { AffiliateLinkContainer, FaqsAccordion, Faq, LazyIframe, ChartSingleRow, DefaultChart, ProductReview} from '@monorepo-nx/std-watch/ui';
import { cdnLoader, FAQPageSEO, slugify } from '@monorepo-nx/std-watch/services';
import Image from "next/image"
import Callout from '../../components/callout';
import * as Sentry from "@sentry/nextjs";


// TODO: Extract this to a global config file
const SITE_DOMAIN = 'stdwatch.com'
const isLinkInternal = ({ href }) => {
    return href.includes(SITE_DOMAIN) || href.toString().startsWith('/') || href.toString().startsWith('#');
}

const parseRTFElement = (element, qaObject, mainEntity) => {
    if (element.type.includes('heading')) {
        if (Object.values(qaObject).length !== 0) {
            // skip the first interation when the qaObject is empty
            mainEntity.push(qaObject)
            qaObject = {}
        }
        qaObject.questionName = element.children[0].text
    }
    else {
        if (element.type === 'paragraph') {
            if (!qaObject.acceptedAnswerText) {
                qaObject.acceptedAnswerText = ''
            }
            if (element.children.length === 1)
                qaObject.acceptedAnswerText += element.children[0].text
            else {
                element.children.forEach(child => {
                    if (child.text)
                        qaObject.acceptedAnswerText += child.text
                    else {
                        // handle the case there is a link inside the paragraph
                        if (child.children?.length > 0 && child.children[0].text?.length > 0) {
                            qaObject.acceptedAnswerText += child.children[0].text
                        }
                    }
                })
            }
        }
        else if (element.type === 'bulleted-list') {
            element.children.forEach(child => {
                if (child.children[0])
                    qaObject.acceptedAnswerText += `${child.children[0].children[0].text},`
            })

        }
        else if (element.type === 'numbered-list') {
            element.children.forEach((child, i) => {
                if (child.children[0])
                    qaObject.acceptedAnswerText += `${i + 1}. ${child.children[0].children[0].text}\n`
            })
        }
        else {
            Sentry.captureException(`FAQ page jsonld got an unfamiliar element type:${element.type}`);
        }
    }
    return qaObject;
}

const generateFAQSchema = (qaData) => {
    let qaObject = {};
    let mainEntity = [];
    qaData.forEach(element => {
        // Parse under try/catch to avoid having a single failure break the entire page
        try {
            qaObject = parseRTFElement(element, qaObject, mainEntity)
        }
        catch (err) {
            Sentry.captureException(err);
        }
    });
    mainEntity.push(qaObject)
    return mainEntity;
}

const renderRTF = (content, references, endpoint, siteConfig) => {
    return (
        <RichText
            loader={cdnLoader}
            references={references}
            renderers={{
                img: ({ node, children, width = 530, height, ...props }) => {

                    const actualWidth = Math.min(width, 530)
                    return (<div className="text-center"><Image
                        placeholder="empty"
                        id={`img-${props.filename}`}
                        loading="lazy"
                        src={props.src}
                        alt={props.altText || "Article image"}
                        width={actualWidth}
                        height={actualWidth * height / width}
                        objectFit={true}
                    /></div>
                    );
                },
                a: ({ node, children, ...props }) => {

                    if ((props.href.includes('/referral'))) {

                        let affName = props.href.split('/referral/').pop().split('/')[0] + '/' + props.href.split('/referral/').pop().split('/')[1]

                        return <AffiliateLinkContainer affName={affName} href={props.href} isButton={props.className} endpoint={endpoint} siteConfig={siteConfig}>
                            {children}
                        </AffiliateLinkContainer>

                    }

                    else if (props.href.includes('https://media.graphassets.com')) {

                        return <img loading="lazy" src={props.href.replace('media.graphassets.com/', 'media.graphassets.com/compress/output=format:webp/resize=width:600/')} alt={props.altText ?? "Article image"} />;
                    }
                    else if (props.href.includes('https://cdn.stdwatch.com')) {

                        return <img loading="lazy" src={props.href} alt={props.alt ?? "Article image"} />;
                    }
                    else {
                        // it is a regular link that may be internal or may be external
                        //  open it in a new tab only if it's not an internal link,
                        let openInCurrentTab = isLinkInternal(props)
                        let linkProps = {
                            href: props.href,
                            target: openInCurrentTab ? '_self' : '_blank',
                            rel: openInCurrentTab ? '' : 'noopener'// Only apply the rel noopener for external links, internal links should not have this set!
                        }
                        return <a {...linkProps} className={'underline decoration-indigo-500'}>{children}</a>
                    }
                },
                h1: ({ children }) => {
                    return <h1 className={'hidden'}>{children}</h1>
                },
                h2: ({ children }) => {
                    const textH2 = children.props.content[0].text
                    const textH2Link = children.props.content[0].children && children.props.content[0].children[0].text
                    const textH2LinkUnderline = children.props.content[1]?.children && children.props.content[1]?.children[0].text
                    return <h2 id={
                        textH2LinkUnderline
                            ? slugify(textH2LinkUnderline)
                            : textH2
                                ? slugify(textH2)
                                : slugify(textH2Link)
                    } >{children}</h2>
                },
                link: {
                    DeepLink: ({ name, cta, affiliate_link, children }) => {
                        return <AffiliateLinkContainer affName={name} affLink={affiliate_link} domain={siteConfig?.domain}>
                            {children}
                        </AffiliateLinkContainer>
                    },
                    Post: ({ children }) => {
                        let linkProps = {
                            href: children.props.references[0].category === "Reviews" ? `/reviews/${children.props.references[0].slug}` : `/blog/${children.props.references[0].slug}`,
                            target: '_self',
                            rel: 'noopener'
                        }
                        return <a {...linkProps} className={'underline decoration-indigo-500'}>{children}</a>
                    },
                    Page: ({ children }) => {
                        let linkProps = {
                            href: children.props.references[0].url === "/" ? `${children.props.references[0].url}` : `/health/${children.props.references[0].url}`,
                            target: '_self',
                            rel: 'noopener'
                        }
                        return <a {...linkProps} className={'underline decoration-indigo-500'}>{children}</a>
                    }
                },
                iframe: ({ url, width, height }) => {
                    return <LazyIframe url={url} width={width} height={height} title={"youtube"} />
                },
                // iframe: ({url, width, height})=>{return <div>{`${url}`}</div>},
                embed: {
                    "Faq": Faq,
                    Promotion: (promotion) => {
                        return (
                        <ChartSingleRow promotion={promotion} colorPalette={siteConfig?.colorPalette} domain={siteConfig?.domain}/>
                        )
                    },
                    Chart: ({name, promotions, className}) => {
                        return (
                            <DefaultChart chart={{name: name, promotions: promotions}} siteConfig={siteConfig} />
                        )
                    },
                    "ProductReview": ProductReview,
                    Topic: ({ name, faqs, ...props }) => {
                        return (
                            <FaqsAccordion name={name} faqs={faqs} href={props.href} />
                        )
                    }
                },
                class: ({ className, children, ...props }) => {
                    if (className.includes('callout')) {
                        return (<Callout className={className}>
                            {children.props.content}
                        </Callout>)
                    }
                    if (className.includes('faqs')) {
                        return (
                            <div className={className}>
                             {children}
                             <FAQPageSEO mainEntity={generateFAQSchema(children.props.content)} /> 
                            </div>
                        )
                    }
                    const parseRTFContent = (children) => {
                        const colorClasses = ['text-violet-500', 'p-10', '[&>*]:text-violet-500', 'bg-orange-500', 'orange-500', 'ring-orange-500', 'bg-indigo-500', 'indigo-500', 'text-white', '[&>*]:text-white', 'text-green-700', 'text-pink-700', 'text-red-500', 'text-blue-500', '[&>*]:text-blue-500', 'text-green-500', '[&>*]:text-green-500'];
                        const dynamicClassName = colorClasses.find((className) => children.props.parent.className.includes(className)) || '';
                        return dynamicClassName;
                    };

                    if (children.props.content[0].type === 'heading-one' || children.props.content[0].type === 'heading-two') {
                        const dynamicClassName = parseRTFContent(children);
                        return (
                            <div className={`[&>*]:${dynamicClassName}`}>
                                {children}
                            </div>
                        )
                    }
                    if (className.includes('cta-button')) {
                        const dynamicClassName = parseRTFContent(children);
                        console.log("Dynamic Class Name", dynamicClassName);
                        return (
                            <div className=''>
                                <button className={`prose bg-${dynamicClassName} hover:prose-a:bg-${dynamicClassName} focus:prose-a:ring-${dynamicClassName} focus:prose-a:ring-2  focus:prose-a:ring-offset-2 focus:prose-a:outline-none prose-a:px-6 prose-a:py-2 prose-a:border prose-a:border-transparent prose-a:rounded prose-a:inline-flex prose-a:items-center rounded transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-300 m-4  prose-a:text-xs prose-a:sm:text-sm  prose-a:text-white hover:prose-a:text-white `}>
                                    {children}
                                </button>
                            </div>
                        )
                    }
                    return (
                        <div className={className}>
                            {children}
                        </div>
                    )
                }
            }}

            content={content}
        />
    )
}


export default renderRTF;
