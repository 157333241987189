import {CheckIcon} from '@heroicons/react/20/solid'
import Image from 'next/image'
import Link from "next/link"
import { useRouter } from "next/router";
import { AffiliateLinkContainer, RatingStars } from '@monorepo-nx/std-watch/ui';
import { SendEvent, ViewPromotionEvent, SelectPromotionEvent, useIntersection } from '@monorepo-nx/std-watch/services';
import React from 'react';
import ReactMarkdown from 'react-markdown'


const handleClick = (e, href, section, promotion, position) => {
  const component = e.target.tagName
  const linkText = e.target.innerText
  SendEvent({ eventName: 'click-component', component: 'promotion', text: linkText, href, section })
  SelectPromotionEvent(promotion.slug, position || 0, [promotion])
}
const CTAButton = ({isDisabled, children, ctaBgColor}) => (
  <div className={`call-to-action block rounded-md bg-${ctaBgColor}-500 py-2 text-center text-md font-bold text-white shadow-sm  ${ isDisabled ? `opacity-50 cursor-not-allowed` : `hover:bg-${ctaBgColor}-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600`}`}>
     {isDisabled ? 'Coming soon!' : children}
</div>
)
const CustomLI = ({ children }) => (
  <li className="flex gap-x-2 lg:pb-1  "> <CheckIcon className="h-6 w-6 text-sm flex-none text-lime-500 font-bold" aria-hidden="true" /><p>{children}</p></li>)

export function ChartSingleRow({promotion, colorPalette, domain}) {
  const showNumber = promotion.showNumber ?? false
  const position = promotion.position
  const router = useRouter();
  const callToAction = promotion.callToAction?? 'Visit Site'
  const ctaBgColor = colorPalette?.["default-cta-button-background-color"]?? "amber"
  const couponBgColor = colorPalette?.["default-coupon-background-color"]?? "teal"
  const triggerRef = React.useRef(null);
  const isVisible = useIntersection(triggerRef, "0px");
  const showRating = router.pathname !== "/"
  
  async function callbackFn() {
    ViewPromotionEvent(promotion.slug, position || 0, [promotion.slug])
  }

  React.useEffect(() => {
    if (isVisible) {
      callbackFn();
    }
  }, [callbackFn, isVisible]);



  const deeplink_name = promotion.deepLink ? promotion.deepLink.name : promotion.advertiser.deepLink?.name
  const deeplink_affiliate_link = promotion.deepLink ? promotion.deepLink.affiliate_link : promotion.advertiser.deepLink?.affiliate_link
  const coupon_text = promotion.couponText ? promotion.couponText : promotion.advertiser.defaultCouponText
  const features_md = promotion.features_md ? promotion.features_md : promotion.advertiser.features_md
  const landing_page_preview_image = promotion.landing_page_preview_image ? promotion.landing_page_preview_image : promotion.advertiser.landing_page_preview_image
  const isDisabled = promotion.advertiser.isDisabled || false
  // const analyticsHref = "/referral/" + deeplink_name
  return (
    <div className=" pb-3 px-3 lg:py-8 not-prose mx-auto bg-white motion-safe:hover:scale-105 ease-in-out duration-300 ring-2 ring-gray-900/5 rounded-lg  shadow-2xl "
      onClick={(e) => handleClick(e, deeplink_name, promotion.section, promotion.slug, promotion.position)}
    >  
        <div className="ribbon-text w-fit h-fit -translate-x-4  translate-y-4 bg-indigo-600 text-white text-base font-bold leading-9 px-4 rounded-tl-md rounded-br-md">
          {promotion.ribbon}
        </div>
        {(showRating && showNumber) && 
          <div className=" -translate-x-7 translate-y-6  text-base text-center font-bold h-9 w-9 border-2 border-black bg-white rounded-full">
            {position + 1}
          </div>}

        {/* mobile */}
        <div className='md:hidden grid grid-cols-2 my-6 gap-x-10 pt-5'>
          <AffiliateLinkContainer
            // legacyBehavior
            affName={deeplink_name}
            affLink={deeplink_affiliate_link}
            dataAdvertiser={promotion.advertiser.name}
            dataTagCategory={"top-provider-mobile"}
            dataTagPosition={"1"}
            dataTagLabel={"top-provider-logo-mobile"} 
            isDisabled={isDisabled}
            domain={domain}            
          >
            <Image
              key={1}
              height={46}
              width={138}
              src={promotion.advertiser.logo ? promotion.advertiser.logo.url : ''}
              alt={promotion.advertiser.logo?.altText ?? 'logo'}
              className="object-contain"
            />
          </AffiliateLinkContainer>

          {showRating &&
            <div className="relative text-center">
              <div className="mx-auto max-w-xs px-1">
                <p className="flex items-baseline justify-center gap-x-1">
                  <span className="text-2xl tracking-tight text-gray-900 score-number">{promotion.score}</span>
                </p>
                <RatingStars score={promotion.score} /> 
              </div>
            </div>}
        </div>

        {/* desktop */}
        <div className={`md:grid ${showRating ? "md:grid-cols-4" : "md:grid-cols-3"} px-3 md:px-4  items-center -mt-2`}>
        {/* col 1 */}
        <div className={`hidden md:block pt-10 col-span-1 row-span-2 text-center`}>
          <AffiliateLinkContainer
            // legacyBehavior
            affName={deeplink_name}
            affLink={deeplink_affiliate_link}
            dataAdvertiser={promotion.advertiser.name}
            dataTagCategory={"top-provider-desktop"}
            dataTagPosition={"1"}
            dataTagLabel={"top-provider-logo-desktop"}
            domain={domain} 
            isDisabled={isDisabled}
          >
            <Image
              key={2}
              height={46}
              width={138}
              src={promotion.advertiser.logo ? promotion.advertiser.logo.url : ''}
              alt={promotion.advertiser.logo?.altText ?? 'logo'}
              className="object-contain"
            />
          </AffiliateLinkContainer>

          {promotion.advertiser.review ? (<p className="text-xl leading-7 text-blue-500  mb-10">
            <Link style={{ textDecoration: 'underline', lineHeight: '26px' }} href={`/blog/${promotion.advertiser.review.slug}`} title={`${promotion.advertiser.name} Review`}>Read Review</Link>
          </p>
          ) : ''}

          <div className='hidden md:block '>
            <AffiliateLinkContainer
              // legacyBehavior
              affName={deeplink_name}
              affLink={deeplink_affiliate_link}
              domain={domain} 
              isDisabled={isDisabled}
            > 
              <Image
                key={3}
                height={144}
                width={192}
                loading="lazy"
                className="flex-none rounded-xl object-cover shadow-xl  lg:max-w-sm"
                src={landing_page_preview_image ? landing_page_preview_image.url : ''}
                alt={landing_page_preview_image?.altText ?? 'preview-image'}
              />
            </AffiliateLinkContainer>
          </div>
        </div>
        {/* col 2 & 3 */}
        <div className="col-span-2 md:mb-4 md:ml-6">
          <div className={`w-full flex-auto md:grid md:grid-cols-1`}>
            <ul className="text-sm font-medium ">
              <ReactMarkdown
                components={{ li: CustomLI }}>{features_md}</ReactMarkdown>
            </ul>
            {coupon_text ?
                <div key={5} className={`mt-3 items-center max-w-fit bg-${couponBgColor}-500 inline-flex text-white p-2 lg:p-2 rounded-br-full rounded-tr-full`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="-ml-0.5 mr-1 h-4 w-4">
                      <circle cx='10' cy='10' r='8' fill='currentColor'></circle>
                    </svg>
                  <AffiliateLinkContainer
                      affName={deeplink_name}
                      affLink={deeplink_affiliate_link}
                      domain={domain} 
                      isDisabled={isDisabled}
                    >
                    <span className="text-white   lg:text-md  mr-2  coupon-promotion"> {coupon_text}</span>
                  </AffiliateLinkContainer>
                </div> : ''}
  
          </div>

        </div>
        {/* col 4 */}
        <div className={`${showRating ? "md:col-span-1" : "md:col-span-2"} md:mb-4 md:ml-6`}>

          <div className="">
            <div className="hidden md:block md:mx-auto">
            {showRating && 
            <div className="rounded-2xl text-center flex flex-col my-3">
                <p className=" flex items-baseline justify-center gap-x-2">
                  <span className="text-2xl tracking-tight text-gray-900 score-number">{promotion.score}</span>
                </p>
                
                <RatingStars score={promotion.score} size={3}/> 
                
                </div>}
              <div ref={triggerRef}></div>
              <AffiliateLinkContainer affName={deeplink_name} affLink={deeplink_affiliate_link} isDisabled={isDisabled} domain={domain} >
                  <CTAButton isDisabled={isDisabled} ctaBgColor={ctaBgColor} >
                  {callToAction}
                </CTAButton>
              </AffiliateLinkContainer>
            </div>
          </div>

        </div>
        {/* Button on the bottom, mobile*/}
        <div ref={triggerRef}></div>
        <div className=" md:hidden py-3">
          <AffiliateLinkContainer affName={deeplink_name} affLink={deeplink_affiliate_link} isDisabled={isDisabled} domain={domain} >
              <CTAButton isDisabled={isDisabled} ctaBgColor={ctaBgColor} >
              {callToAction}
            </CTAButton>
          </AffiliateLinkContainer>
        </div>
 
      </div>
    </div>
  );
}
export default ChartSingleRow;